import React from "react";
import NavBar from "../Components/NavBar";

const PrivacyPolicy = () => {
  return (
    <div>
      <NavBar />

      <div className="container" style={{ textAlign: "justify" }}>
        <h5 className="text-primary-emphasis text-center fw-bold my-3">
          Privacy Notice
        </h5>
        <div>
          <h6 className="fw-bold bg-light p-2">
            We encourage you to read this Privacy Policy in its entirety to
            understand the information we collect and how we use and disclose
            it.
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            Thank you for visiting BharatAbroad website. BharatAbroad
            appreciates your interest in our company and yachting services we
            offer. By visiting our website, you are accepting the Privacy Policy
            of use and Terms and Conditions in any other manner. Our Privacy
            Policy applies to all websites and online services of
            BharatAbroad.com. The Privacy Policy describes our use or disclosure
            of anonymous or personally identifiable information collected on or
            through our Website/ Platform.
          </p>
          <h6 className="fw-bold bg-light p-2">
            What Information do we gather -
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            The anonymous information we gather refers to information such as
            First, Last Name, Email and Phone Number when you subscribe to our
            mailing list, purchase a boat/yacht, inquire about a yacht, register
            for our events, visit us at a Boat Show, contact us for any yachting
            services or support and if you apply for a job. Additional
            information includes the IP address (is the Internet Protocol (IP) a
            number that is assigned to your device when you access the
            internet), internet service provider (ISP) information, date/time
            stamp, and click stream data. All anonymous information we collect
            through our website is collected when your device accesses the
            BharatAbroad site and associated platforms. We use this information
            to improve your experience and develop new experiences and products
            based on user interests, behavior, and demographics. If you are a
            resident of the EU, the privacy policy provides you information
            about the “GDPR” - General Data Protection Regulation. Your rights
            under GDPR are explained in this Privacy Notice. You can read more
            about your rights here
          </p>
          <ul style={{}}>
            <li>The right to be informed</li>
            <li>The right of access</li>
            <li>The right to rectification</li>
            <li>The right to erasure</li>
            <li>
              The right to restrict processing – You can read more about this
              right
            </li>
            <li>
              The right to data portability – You can read more about this right
            </li>
            <li>The right to object – You can read more about this right</li>
            <li>
              Rights in relation to automated decision making and profiling
            </li>
            <li>
              If you are a resident of California, the privacy policy provides
              you information about the California Consumer Privacy `Act or
              (“CCPA“). Your rights under CCPA are also explained below in this
              Privacy Notice – you can read more about CCPA here.
            </li>
            <li>
              The right to access the personal information that a business holds
              on them
            </li>
            <li>
              The right to know what personal data a business plans on
              collecting from them before the point of collection
            </li>
            <li>
              The right to opt in or out of marketing, analytics, and other
              similar activities
            </li>
            <li>The right to equal services without discrimination</li>
            <li>The right to request deletion of personal data</li>
            <li>
              If you wish to exercise any of your rights, please mail us
              privacy@BharatAbroad.com
            </li>
          </ul>
          <h6 className="fw-bold bg-light p-2">
            How Data gathered is used for improving the user experience
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            Information about our customers is an important part of our
            business, and we are not in the business of selling it to others. We
            are committed to protecting your information that identifies you
            personally. When using the Portal forms requesting services,
            subscribe in our Site/Platform you consent, BharatAbroad to
            use/share the data collected about you to provide, improve user
            experience of the products and services we offer as below To deliver
            and improve our Yacht broker and other yachting/boating related
            services To share yacht industry trends, new product releases,
            events, Promotions, and information we think will be of interest to
            you Receive emails for the products and services you have requested
            via BharatAbroad site/Platform/affiliated partner sites Analyze site
            usage, page visits, and other interests in connection to the
            Yacht/Boat products and Yachting/Boating Services offered on
            BharatAbroad Site/Platform Improve/present Site features/content
            with advertisements we think will be interest to you Share with our
            affiliated partners to contact for the yachting services, Yacht
            Financing, Insurance, Storage, and Transportation requested using
            the portal forms on BharatAbroad Site/Platform Detect and prevent
            any malicious attacks on BharatAbroad and other affiliated
            sites/platform
          </p>
          <h6 className="fw-bold bg-light p-2">
            IBA – Interest Based Advertising / Online Behavioral Advertising
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            BharatAbroad Site/Platform participate in IBA - interest-based
            advertising / Online Behavioral Advertising. We use data collected
            from your online activity on a digital device (e.g., smart phone,
            tablet, computer, etc.) using cookies, pixel tags, and other
            technologies associated with your unique browser, device identifier,
            or Internet Protocol (IP) address to show ads that may be of
            interest to you. These Interest Based advertisements may appear on
            the other websites or properties and services.
          </p>
          <h6 className="fw-bold bg-light p-2">
            Ad Sponsors and Other Advertisers it.
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            BharatAbroad Site/Platform offers Yacht/OEM’s, Yacht/Boat Brokers,
            and other advertisers to promote their products and services as
            impressions in certain pages of BharatAbroad Website/Platform.
            BharatAbroad Platform does not allow to post content or items in an
            inappropriate category or areas on the Sites, violate any laws,
            third party rights, or our policies. You can read more about our Ad
            Sponsor and advertising in our advertiser terms and conditions.
          </p>
          <h6 className="fw-bold bg-light p-2">
            Retention of Data/Information & Storage
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            BharatAbroad will retain and use the anonymous information gathered
            when we have an ongoing legitimate business need, to provide you
            with a service you have requested or to comply with applicable legal
            and statutory requirements. Occasionally BharatAbroad may oblige the
            Government agency request when asked. BharatAbroad will attempt to
            notify affected users to give them an opportunity to object, if not
            prohibited by law. BharatAbroad is a US based company, and you
            acknowledge that data you provide using our BharatAbroad
            portal/Platform forms is governed by U.S law and consent to
            processing, transferring, and storing the data.
          </p>
          <h6 className="fw-bold bg-light p-2">Security of Data/Information</h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            BharatAbroad Site/Platform takes reasonable measures to secure the
            anonymous information we gathered from unauthorized use, access, and
            disclosure, both during transmission and at rest. Note, no
            transmission of information via the internet nor electronic storage
            solutions can be entirely secure. BharatAbroad cannot guarantee
            absolute security.
          </p>
          <h6 className="fw-bold bg-light p-2">Why we use Cookies</h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            We use cookies and related technologies to personalize and improve
            your experience as you use our website. A cookie is a small file
            that can be placed on your device that allows us to recognize and
            remember you. It is sent to your browser and stored on your
            computer's hard drive, tablet, mobile device. Please see our Cookie
            Policy for details of the cookies that are set on our
            website/Platform.
          </p>
          <h6 className="fw-bold bg-light p-2">
            Changes to this Privacy Policy - This Privacy Policy was revised 6th
            February.2023
          </h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            Please note that this Privacy Policy may be amended from time to
            time. You should check back for the most current version before
            relying on any of the provisions in this privacy policy. We will
            provide notice of material changes to the policy, either by posting
            a notice on our websites, by sending an email, or by other
            reasonable methods.
          </p>
          <h6 className="fw-bold bg-light p-2">To Contact Us</h6>
          <p style={{ textAlign: "justify" }} className="p-2">
            If you have any questions or concerns about our use of your personal
            information, please email: privacy@BharatAbroad.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
