import React from "react";
import NavBar from "../Components/NavBar";
const MarketingPolicy = () => {
  return (
    <div>
      <NavBar />

      <div className="container text-center" style={{ textAlign: "justify" }}>
        <h5 className="text-primary-emphasis fw-bold my-3">
          Advertising / Privacy / Terms & Conditions
        </h5>
        <div>
          <p style={{ textAlign: "justify" }}>
            Thank you for your interest in BharatAbroad website/Platform.
            BharatAbroad appreciates your interest in our company products and
            services we offer. We strongly recommend that, as you read this
            Advertising/Privacy/terms and conditions and must accept and agree
            to comply with terms and conditions that apply to the advertisement
            Content, interest-based advertisement posts, Blog contributions and
            other content that you submit to us or post on BharatAbroad
            Website/Platform. You are responsible for maintaining the required
            confidentiality of any information of BharatAbroad utilized in
            connection with your use of this Website/Platform. For information
            regarding our data collection and use practices, please see our
            Privacy Policy.
          </p>
          <p style={{ textAlign: "justify" }}>
            BharatAbroad reserve the right to change, modify, delete the
            Advertising Terms and Conditions and privacy policies from time to
            time when deemed appropriate and applied with immediate effect.
            Changes will not be communicated separately. We encourage the
            visitors, advertisers, customers, and blog contributors to check
            periodically check of all changes posted. BharatAbroad
            Website/Platform reserve the right to change, terminate any access
            provided, suspend/discontinue, the Website/platform or parts of
            website/platform or services at any time without any notice and if
            we believe customer/advertisers/Blog contributors conduct violates
            applicable law or is harmful to the interests of BharatAbroad
            website/Platform, to its customers and affiliates or licensing
            partners.
          </p>
          <p style={{ textAlign: "justify" }}>
            BharatAbroad Website/Platform owns all the intellectual property
            rights of the BharatAbroad Logo, Software programming source code,
            site framework, content created, text, page layouts rendering pages,
            integrations, communication protocols developed using commercial IT
            products, and data information stored to interface with customers,
            Advertisers, Blog content contributors supported by the applicable
            US and other countries trade secret laws. To download BharatAbroad
            editorial content related to yachting services for personal and
            non-commercial use only, we grant limited and non-exclusive rights
            with the exception to intellectual property rights mentioned above.
          </p>
          <p style={{ textAlign: "justify" }}>
            By using the advertising services offered in BharatAbroad
            website/Platform, the advertiser- who is a person, or the
            advertising agency or media buyer, Blog Contributors, placing the
            order for the advertisement of the product or service, Blog
            Contributors accepts and agrees to the following terms in full.
          </p>
          <p style={{ textAlign: "justify" }}>
            Customers, digital Advertisement networking agencies, Ad Sponsors,
            Blog Contributors shall be solely responsible for providing to
            BharatAbroad Website/Platform all of the text, pictures, graphics,
            sound, video, programming code and other media which comprise the
            graphic or html file that comprises the Online Advertising in
            conformance with BharatAbroad Advertisement Specifications. For ad
            specs click here In submitting Advertising to BharatAbroad
            Website/Platform, the Customer, Ad Sponsors/Advertisement networking
            agencies grants BharatAbroad Website/Platform a worldwide,
            royalty-free, irrevocable, perpetual license to publish/post, as
            well as the right to sub-license all such rights to any third-party
            provider. Customers/Ad Sponsors/ Digital advertisement network
            agencies, including interest-based advertisers advertisement
            content/posting is accurate, truthful and nondeceptive and does not
            violate any country laws regarding false advertising.
          </p>
          <p style={{ textAlign: "justify" }}>
            The customer/Ad Sponsor/ Digital advertisement network agencies
            including interest-based advertisers shall ensure it complies with
            all applicable laws and regulations with respect to such Data
            Collecting Technology and that all Analytics Data will be archived
            and managed upon the end of the relationship between BharatAbroad
            Website/Platform and the customer/Ad Sponsor/ Digital advertisement
            network agencies. BharatAbroad Website/Platform reserves all rights
            to reject, deny, cancel, revise, change the position of ad space,
            and remove any advertisement we consider inappropriate, misleading,
            offensive, deceptive, defamatory or libelous, destructive, lewd,
            pornographic or obscene, excessively profane, racist, ethnically
            offensive, threatening, infringing, excessively violent, ,
            gambling-related, or promote any discriminatory activity, promote
            any type illegal drugs, arms trafficking, create a risk to a
            person's safety or health, compromise national security, interfere
            with law enforcement investigations and promoting, advocating or
            facilitating terrorism, terrorist-related activities, that are
            contrary to these terms.
          </p>
          <p style={{ textAlign: "justify" }}>
            Customer/Ad Sponsor/Advertising network agencies are solely
            responsible for compliance and does not violate with all advertising
            regulations/laws, industry standard, codes, other applicable
            regulations, including without limitation any country laws and
            regulations governing export control, as to the content of the
            Advertising content and artifacts, including the blog content
            provided to BharatAbroad Website/Platform or created by
            Website/Platform on behalf of the customer/Ad Sponsor/Advertising
            network agencies.
          </p>
          <p style={{ textAlign: "justify" }}>
            Content developed/created for publishing by BharatAbroad website
            developers / content creator on behalf advertiser, Ad Sponsors,
            Advertisement network agencies must be formally approved via email
            by customers/Ad Sponsor/Advertising network agencies authorized
            person/team members. BharatAbroad Website/Platform makes no
            guarantees as to the number of visitors to the site or the number of
            impressions. Interest based advertising and online Advertising will
            not be subject to any impression or viewability guarantees.
          </p>
          <p style={{ textAlign: "justify" }}>
            Customers/Ad Sponsors/ Digital advertisement network agencies,
            including interest-based advertisers and users using the site is not
            allowed to create, facilitate any security vulnerabilities,
            distribute viruses, Ransomwares, cancelbots or other harmful or
            deleterious programming routines and promote programs which
            compensate or provide incentives to users for clicking ads or
            offers, performing searches, surfing websites or reading emails
            which may harm BharatAbroad Website/platform and it users end user
            computing devices. The customer/Ad Sponsor/ Digital advertisement
            network agencies distributing interest-based advertisements shall
            redirect to the advertisers site(s) when clicked and should ensure
            that it has a lawful basis for the use of such data collecting
            technology and the collection of Advertiser Data from visitors to
            the Advertisers Site(s) and that the Advertiser and the Advertiser’s
            Site complies with all applicable data protection and/or privacy
            laws, regulations and codes of practice.
          </p>
          <p style={{ textAlign: "justify" }}>
            All Analytics Data collected by the digital advertisers networking
            agencies through such Data Collecting Technologies will be
            confidential information owned by BharatAbroad Web Site/Platform and
            will not be disclosed by the digital advertisers networking agencies
            to any third party without BharatAbroad Web Site/Platform’s written
            consent. BharatAbroad Website/Platform does not guarantee
            continuous, uninterrupted access, secure access of the
            website/Platform by users, BharatAbroad will use reasonable efforts
            to keep the site available online beyond the reasonable control of
            BharatAbroad. BharatAbroad Website/Platform will not be liable for
            any delay or failure to publish/post the Advertising caused by a
            factor outside of BharatAbroad Website/Platform control, such as
            adverse climate conditions, fire, war, civil disorder/unrest,
            accident, terrorism, regulatory restrictions, epidemic, pandemic,
            sanctions, power and network outages, judicial orders, and any other
            circumstance.
          </p>
          <p style={{ textAlign: "justify" }}>
            Digital Advertiser, Advertising Network Agencies, Interest based
            advertisers may cancel an Advertisement in writing to BharatAbroad.
            The minimum notice period for cancellation by the Advertiser is 30
            days unless agreed otherwise. BharatAbroad Website/Platform shall
            not be responsible, under any circumstances, for any loss or damage
            suffered which could not be contemplated by BharatAbroad
            Website/Platform and by the Digital Advertiser, Advertising Network
            Agencies, Interest based advertisers. We are not liable for any loss
            of Revenue, Opportunity, Goodwill, Reputation, and savings.
            BharatAbroad Website/Platform maximum total liability for any loss
            or damage arising out of or in relation to any Advertisement whether
            in contract or otherwise shall not exceed the total amount of the
            charges for the relevant Advertisement actually paid for the use of
            the services. All warranties and indemnities stated in these Terms
            will survive the termination of any arrangement or contract which is
            subject to these Terms.
          </p>
          <p style={{ textAlign: "justify" }}>
            Customer(s), Advertisers, Advertising Network agencies, Interest
            Based Advertisers, Blog contributors will indemnify BharatAbroad
            Website/Platform and its officers, employees, contractors and agents
            against any costs, expenses including any attorney’s fees, losses,
            damages and liability suffered or incurred by them arising from the
            Customer(s), Advertisers, Advertising Network agencies, Interest
            Based Advertisers, Blog contributors breach of these Terms and any
            negligent or unlawful act or omission of the Customer(s),
            Advertisers, Advertising Network agencies, Interest Based
            Advertisers in connection with the Advertising posted.
          </p>
          <p style={{ textAlign: "justify" }}>
            Prices/Fees/Rates will be quoted in US Dollars and exclusive of all
            applicable taxes. Digital Advertiser, Advertising Network Agencies,
            Interest based advertisers are responsible for approving the quotes
            in writing and paying all the fees and applicable taxes in a timely
            manner with a valid payment method. BharatAbroad Website/Platform
            will invoice the Digital Advertiser, Advertising Network Agencies,
            Interest based advertisers at the contact address/email provided.
            Should a purchase or a confirming Order number be a prerequisite for
            payment of invoice, the Digital Advertiser, Advertising Network
            Agencies, Interest based advertisers must supply when confirming to
            post the advertisement.
          </p>
          <p style={{ textAlign: "justify" }}>
            Digital Advertiser, Advertising Network Agencies, Interest based
            advertisers shall agree to pay the Invoice amount in full per
            payment terms agreed and will be clear of any other taxes imposed by
            any tax authority, such as withholding tax. If the invoice amount is
            subject to any withholding tax or other relevant taxes, then the
            Digital Advertiser, Advertising Network Agencies, Interest based
            advertisers is liable to pay such taxes.
          </p>
          <p style={{ textAlign: "justify" }}>
            If the Digital Advertiser, Advertising Network Agencies, Interest
            based advertisers fails to make any payment due or the account is
            past due by 90 days of the payment terms to BharatAbroad
            Website/Platform, we may use Fair Debt Collection Practices Act to
            collect fees owed by retaining collection agencies and legal
            counsel. During the amount collection, BharatAbroad Website/Platform
            shall have all rights to cancel the digital advertisement campaign
            for which the Digital Advertiser, Advertising Network Agencies,
            Interest based advertisers submitted/issued a PO or by written
            consent.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>LAST UPDATED MARCH - 2023</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MarketingPolicy;
