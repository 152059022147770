import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavBar from "../../Components/NavBar";

const ImmigrationGuidelines = () => {
  return (
    <>
      <NavBar />
      <Container fluid className="my-4">
        <Row>
          <Col lg={9}>
            <h4 className="my-4 fw-bold">
              General Guidelines for those Travelling to India
            </h4>
            <ul>
              <li>
                Immigration Clearance: Both Indian citizens and foreigners must
                obtain clearance at arrival and departure.
              </li>
              <li>
                Visa & Entry: Visa approval doesn’t guarantee entry; final
                discretion lies with immigration.
              </li>
              <li>
                Valid Documents: Foreign nationals need a valid passport and
                visa, unless exempted.
              </li>
              <li>
                Biometrics & Stamping: Passport biometrics may be taken and
                stamped at immigration.
              </li>
              <li>
                Entry Points: Foreigners must use Authorized Immigration Check
                Posts (ICPs).
              </li>
              <li>
                Passport Stamping: Ensure arrival/departure stamps are on your
                passport; missing stamps can be obtained at FRRO or ICP.
              </li>
              <li>
                Visa Adherence: Foreign nationals must comply with their visa
                purpose.
              </li>
              <li>
                Tourist Stay: Maximum 180 days per year for tourist visas.
              </li>
              <li>
                Restricted Areas: Permission needed for Protected, Restricted,
                and Cantonment Areas.
              </li>
              <li>
                Religious Activity: No religious preaching, conversion, or
                propaganda.
              </li>
              <li>
                Health Precautions: Yellow Fever certificate required if
                traveling from affected countries; medical screening may be
                necessary for arrivals from areas with public health concerns.
              </li>
              <li>
                For more details about health regulations, please{" "}
                <a href="https://boi.gov.in/boi/public/content/pages/fdae94c4-b8a5-4f2a-8d1f-a4e53863c315">
                  click here
                </a>
                .
              </li>
            </ul>

            <h4 className="my-4 fw-bold">
              Country Specific Guidelines - United States America
            </h4>
            <ul>
              <li>
                How long can I stay in India (Stay stipulation) on a Tourist
                Visa?
                <br />
                Ans: Subject to the Validity of the Tourist Visa issued,
                continuous stay for each visit shall not exceed 180 days and the
                total aggregate stay by a foreigner in India on Tourist Visa in
                one calendar year shall be restricted to 180 days.
              </li>
              <li>
                Can I avail Visa on Arrival facility to enter India?
                <br />
                Ans: No, You are not eligible for Visa on Arrival.
              </li>
              <li>
                Which are the Immigration Entry Points (Immigration Check Post)
                through which I can enter India from?
                <br />
                Ans: Persons holding e-Visa will be allowed to enter into India
                through designated International Airports and seaports. Click
                here for details on{" "}
                <a href="https://boi.gov.in/boi/public/pages/8ff96bfd-211b-4ba3-8a41-e2467867cf9e">
                  Immigration Authorized Entry Points
                </a>
                .
              </li>
              <li>
                Do I need a visa to enter India?
                <br />
                Ans: Yes, Visa is required to enter India.
              </li>
              <li>
                Can I avail e-Visa (Online Visa) facility to enter India?
                <br />
                Ans: Yes, you are eligible for an e-Visa. Visit{" "}
                <a href="http://www.indianvisaonline.gov.in">
                  www.indianvisaonline.gov.in
                </a>{" "}
                for details.
              </li>
              <li>
                How many “Medical Attendants” can I bring on a “Medical Visa”?
                <br />
                Ans: Not more than two persons may be allowed at a time for the
                grant of ‘MED-2’ visa (Medical Attendant Visa). For FAQ Click
                here.
              </li>
              <li>
                Do I need any Medical or Vaccination certificate to enter India?
                <br />
                Ans: Click here to know more about Health Regulations.
              </li>
            </ul>
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <h4 className="my-4 fw-bold">Business in India</h4>
            <ul>
              <li>
                Business Visa Requirement: Foreigners visiting India for
                business setup, meetings, technical discussions, or commercial
                sports events must obtain a Business Visa.
              </li>
              <li>
                Registration: Business Visa holders staying over 180 days in a
                calendar year must register with the FRRO/FRO.
              </li>
              <li>
                Visa Extension: Extension is possible if business turnover is at
                least Rs. 1 crore per annum within 4 years of visa issuance.
              </li>
              <li>
                Tax Compliance: Business visitors must adhere to Indian tax
                regulations.
              </li>
              <li>
                Exit Requirement: If visa extension is denied, departure from
                India is required by visa expiration.
              </li>
            </ul>

            <h4 className="my-4 fw-bold">Study in India</h4>
            <ul>
              <li>
                Student Visa Requirement: Foreigners pursuing full-time courses
                or research at recognized institutions need a Student Visa.
              </li>
              <li>
                Special Student Visa (S-3): Required for studying yoga, Vedic
                culture, Indian dance, music, or Buddhist studies.
              </li>
              <li>
                Internships: Student (S-6) Visa is necessary for internships in
                Indian organizations.
              </li>
              <li>
                Registration: Foreign students on visas over 180 days must
                register with the FRRO within 14 days of arrival.
              </li>
              <li>
                Employment Restriction: No employment or business activity is
                allowed on a Student Visa.
              </li>
              <li>
                Financial Requirements: Students must open an NRO account and
                share details with the FRRO.
              </li>
              <li>
                Change of Institution: Permitted for valid reasons, such as lack
                of facilities.
              </li>
              <li>
                Restricted Areas: RAP/PAP is needed if the institution is in
                restricted/protected zones.
              </li>
              <li>
                Discontinuation: Notify the FRRO if studies are discontinued.
              </li>
            </ul>
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <h4 className="my-4 fw-bold">Tourism in India</h4>
            <ul>
              <li>
                Tourist Visa Purpose: Foreigners visiting for recreation,
                sightseeing, meeting friends/relatives, short yoga programs, or
                short medical treatment should obtain a Tourist Visa.
              </li>
              <li>
                Group Tourist Visa: Available for travelers from eligible
                countries in groups of 4+ through recognized travel agencies.
              </li>
              <li>
                Stay Limit: Maximum of 180 days per calendar year on an
                e-Tourist or Tourist Visa; continuous stay restrictions apply.
              </li>
              <li>
                Non-Convertible: Tourist visas cannot be extended or converted.
              </li>
              <li>
                Yoga Programs: Short-term yoga programs (up to 6 months, no
                certification) allowed.
              </li>
              <li>
                Re-entry Restriction: Nationals of certain countries must wait 2
                months between Tourist Visa visits.
              </li>
            </ul>

            <h4 className="my-4 fw-bold">Medical Treatment in India</h4>
            <ul>
              <li>
                Medical Visa Requirement: Foreigners seeking treatment in
                recognized hospitals or centers accredited by the Indian
                government should obtain a Medical Visa.
              </li>
              <li>
                Country-Specific Guidelines: Different rules apply based on
                nationality regarding Medical Attendant Visas.
              </li>
              <li>
                Important Notes: Changing the hospital or treatment location is
                allowed case-by-case, with proof.
              </li>
            </ul>
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <h4 className="my-4 fw-bold">Visa Extension or Visa Conversion</h4>
            <ul>
              <li>
                Foreign Nationals who are unable to leave India within the
                validity of their visa due to justifiable reasons should apply
                for the appropriate visa service.
              </li>
              <li>
                This request should be made within the validity of their current
                visa, allowing reasonable time for processing.
              </li>
              <li>
                If the extension or conversion of the visa is not granted within
                the validity of their current visa, they must depart from India.
              </li>
            </ul>

            <h4 className="my-4 fw-bold">
              Overseas Citizen of India (OCI) Cardholder
            </h4>
            <ul>
              <li>
                OCI Card Introduction: The Government of India provides the OCI
                Card to strengthen connections with the Indian diaspora,
                allowing for hassle-free travel, investments, and benefits in
                social, economic, and educational areas.
              </li>
              <li>
                Eligibility for OCI Card: Former Indian citizens, descendants of
                Indian citizens, and foreign spouses of Indian citizens or OCI
                Cardholders are eligible.
              </li>
              <li>
                Ineligible for OCI: Individuals with ancestry from certain
                countries, foreign military personnel, and applicants holding
                diplomatic passports are not eligible.
              </li>
              <li>
                PIO Scheme Merger: The PIO scheme has merged into the OCI
                scheme, and existing PIO cardholders should convert their cards
                to OCI cards.
              </li>
              <li>
                OCI Card Surrender/Cancellation: OCI cards must be surrendered
                if the cardholder remarries or engages in fraudulent activities.
              </li>
            </ul>
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <h4 className="my-4 fw-bold">
              Who are Foreigners/Persons of Indian Origin (FIO)?
            </h4>
            <ul>
              <li>
                Definition of FIO: An individual is considered FIO if they
                previously held an Indian passport or have Indian ancestry,
                excluding citizens of certain countries.
              </li>
              <li>
                Visa for FIO: FIOs visiting India should apply for an Entry
                (X-1) visa, though other visa types may be obtained based on
                visit purpose.
              </li>
              <li>
                Eligibility for X-1 Visa: Includes children/spouses of Indian
                citizens and OCI cardholders born abroad.
              </li>
              <li>
                Keep in mind: Foreigners of Indian origin must abide by all laws
                and regulations of India.
              </li>
            </ul>

            <h4 className="my-4 fw-bold">For More Information</h4>
            <ul>
              <li>
                <a href="https://www.india.gov.in/india-business-portal">
                  Business in India
                </a>
              </li>
              <li>
                <a href="https://studyinindia.gov.in">Study in India</a>
              </li>
              <li>
                <a href="https://tourism.gov.in">Tourism in India</a>
              </li>
              <li>
                <a href="https://boi.gov.in/boi/public/content/pages/80f79bcc-d404-45c0-a0f0-2af64ea5301a">
                  Medical Treatment in India
                </a>
              </li>
              <li>
                <a href="https://ociservices.gov.in">OCI Card Information</a>
              </li>
            </ul>
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
          </Col>
          <Col lg={3} md={3}>
            <div className="d-flex justify-content-center">
              <small>Advertisement</small>
            </div>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ImmigrationGuidelines;
