import React from "react";
import { Helmet } from "react-helmet";

const TechHome = () => {
  return (
    <div>
      {" "}
      <Helmet></Helmet>
      Tech Home
    </div>
  );
};

export default TechHome;
