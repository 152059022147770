import React from "react";
import NavBar from "../../Components/NavBar";
import SportsNavBar from "../../Components/Sports/SportsNavBar";

const Golf = () => {
  return (
    <>
      <NavBar />
      <SportsNavBar />
      Golf
    </>
  );
};

export default Golf;
