import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavBar from "../../Components/NavBar";

const Immusa = () => {
  return (
    <>
      <NavBar />
      <Container fluid className="my-4">
        <Row>
          <Col lg={9}>
            <h4 className="my-4 fw-bold">About the USA</h4>
            <p>
              The United States is a global hub of opportunity and quality of
              life, attracting individuals and families from around the world.
              Its dynamic economy supports innovation, career advancement, and
              financial stability, while the educational institutions are
              globally renowned, offering top-tier learning experiences from
              primary levels to prestigious universities. The nation's
              commitment to freedom, diversity, and inclusivity fosters a
              culture where personal and professional growth thrives. With
              access to world-class healthcare, a rich cultural landscape, and a
              high standard of living, the United States stands as a beacon of
              progress, offering a welcoming environment for all who seek to
              build a fulfilling life.
            </p>
            <h4 className="my-4 fw-bold">Prominent States in the USA</h4>
            <ul>
              <li>New York</li>
              <li>Las Vegas</li>
              <li>Orlando, Florida</li>
              <li>Atlanta</li>
              <li>Miami</li>
              <li>Washington</li>
              <li>Washington, D.C.</li>
              <li>San Francisco</li>
              <li>Denver</li>
              <li>Louisville, Kentucky</li>
              <li>Houston</li>
            </ul>
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <h4 className="my-4 fw-bold">Types of US Visas</h4>
            <ul>
              <li>
                <a href="https://www.uscis.gov/working-in-the-united-states/students-and-exchange-visitors/students-and-employment">
                  Student visa
                </a>{" "}
              </li>
              <li>
                <a href="https://travel.state.gov/content/travel/en/us-visas/tourism-visit/visitor.html/visa">
                  Tourist visa
                </a>
              </li>
              <li>
                <a href="https://www.uscis.gov/working-in-the-united-states/h-1b-specialty-occupations">
                  H-1B visa
                </a>
              </li>
              <li>
                <a href="https://travel.state.gov/content/travel/en/us-visas/immigrate/employment-based-immigrant-visas.html">
                  Employment-based visas
                </a>
              </li>
              <li>
                <a href="https://travel.state.gov/content/travel/en/us-visas/immigrate/family-immigration.html">
                  Family-based Immigration
                </a>
              </li>
            </ul>
            <h4 className="my-4 fw-bold">US Visa Categories</h4>
            <h5 className="fw-bold">Immigrant Visa</h5>
            <p>
              Issued to foreign candidates who plan on living and working in the
              USA on a permanent basis.
            </p>
            <h5 className="fw-bold">Non-Immigrant Visa</h5>
            <p>
              Issued to foreign candidates who plan on living and working in the
              USA on a temporary basis.
            </p>
            <h4 className="my-4 fw-bold">US Green Card</h4>
            <p>
              The Green Card, commonly known as a permanent residence card,
              authorizes a Non-U.S. candidate to gain permanent residency in the
              country. The Green Card holder can live and seek employment in any
              part of the country and will also qualify to apply for US
              citizenship after three to five years.
            </p>
            <p>
              <strong>Did you know?</strong> Over 1 million Green Cards are
              issued every year by the US government.
            </p>
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <h4 className="my-4 fw-bold">Green Card Eligibility</h4>
            <p>
              The eligibility criteria to apply for a US Green Card are as
              follows:
            </p>
            <h5 className="fw-bold">
              Apply for a Green Card via Employment as an Immigrant Worker:
            </h5>
            <ul>
              <li>
                <strong>First Preference Immigrant Worker:</strong>{" "}
                Extraordinary skills in athletics, business, education, or arts;
                renowned experience as a researcher/professor; or a
                multinational executive/manager.
              </li>
              <li>
                <strong>Second Preference Immigrant Worker:</strong>{" "}
                Professionals with an advanced degree or exceptional skills in
                arts, business, or sciences.
              </li>
              <li>
                <strong>Third Preference Immigrant Worker:</strong> Skilled
                workers with a minimum of two years of job experience or
                training, or professionals with at least a Bachelor’s degree.
              </li>
            </ul>
            <h5 className="fw-bold">Apply for a Green Card via Family:</h5>
            <ul>
              <li>Unmarried children of US citizens under the age of 21.</li>
              <li>Spouses of US citizens.</li>
              <li>Parents of US citizens who are at least 21 years of age.</li>
            </ul>
            <h4 className="my-4 fw-bold">US Immigration Eligibility</h4>
            <p>
              The eligibility criteria to migrate to the US differ based on the
              type of permit. However, the general eligibility requirements for
              US immigration are as follows:
            </p>
            <ul>
              <li>Age: Must be over 18 years of age.</li>
              <li>
                Educational Qualifications: Must meet the minimum educational
                qualification that is equivalent to or higher than secondary
                education in the USA.
              </li>
              <li>
                Language Proficiency: Must achieve a minimum score of at least
                6+ in IELTS or TOEFL.
              </li>
              <li>
                Work Experience: Must have at least 1 year of professional work
                experience in the field of your choice.
              </li>
              <li>
                Employment Offer: You can migrate to the US with or without an
                employment offer.
              </li>
            </ul>
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
            <h4 className="my-4 fw-bold">How to Migrate to the US</h4>
            <p>
              There are many ways to migrate to the USA; some of the most common
              ways include:
            </p>
            <h5 className="fw-bold">Migrate to the US as an Employee:</h5>
            <p>
              Temporary work visas allow candidates to enter the United States
              for the purpose of working for a sponsoring employer. The visa is
              valid for a set amount of time after which the applicant must
              return to their home country. Work Permit visas can be granted to
              applicants if their potential employer submits a petition with the
              USCIS on their behalf.
            </p>
            <h5 className="fw-bold">
              Migrate to the US through the Investor Route:
            </h5>
            <p>
              The EB-5 investment visa permits affluent persons to obtain a
              green card providing permanent residence status as soon as they
              enter the country, without having to wait significant periods of
              time. To qualify for this visa, you must be willing to invest a
              large sum of money, ranging from $500,000 to $1 million, in an
              American company and actively participate in its management.
            </p>
            <h5 className="fw-bold">Migrate with Your Family to the US:</h5>
            <p>
              Permanent residency in the United States is also available to
              family members of US citizens or Green Card holders. There are two
              types of family-based immigration, depending on the degree of
              affinity:
            </p>
            <ul>
              <li>(Future) spouses</li>
              <li>Unmarried children under the age of 21</li>
              <li>Parents of US citizens or Green Card holders</li>
            </ul>
            <p>
              They will be granted their permanent residence permit immediately.
              Siblings and children of US citizens or Green Card holders over
              the age of 21 are included in the second group, but they are often
              subjected to lengthier wait times.
            </p>
            <h4 className="my-4 fw-bold">US Immigration Process</h4>
            <p>
              You can follow the simple steps given below to apply for US
              Immigration:
            </p>
            <ol>
              <li>Check your eligibility.</li>
              <li>Sort out the requirements of the visa.</li>
              <li>Apply for the visa.</li>
              <li>Await the status of your visa.</li>
              <li>Migrate to the USA.</li>
            </ol>
            <a href="https://www.uscis.gov/working-in-the-united-states/h-1b-specialty-occupations">
              Contact USCIS to know more
            </a>{" "}
            <div className="text-center py-4">
              <h6 style={{ fontSize: "11px" }}>ADVERTISEMENT</h6>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <img
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/IMG-20240821-WA0057.jpg"
                  alt=""
                  className=""
                />
              </Link>
            </div>
          </Col>
          <Col lg={3} md={3}>
            <div className="d-flex justify-content-center">
              <small>Advertisement</small>
            </div>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2 border"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                fluid
              />
            </Link>
            <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
              <Image
                className="mx-auto d-block pt-2"
                src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                fluid
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Immusa;
