import React from "react";
import NavBar from "../../Components/NavBar";
import SportsNavBar from "../../Components/Sports/SportsNavBar";

const Chess = () => {
  return (
    <>
      <NavBar />
      <SportsNavBar />
      Chess
    </>
  );
};

export default Chess;
