import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";

import { Link, NavLink } from "react-router-dom";
import NavBar from "../Components/NavBar";

const Immigiration = () => {
  const isLargeScreen = window.innerWidth >= 992; // Example for screen size handling
  const [tech, setTech] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTech = async () => {
      try {
        const response = await fetch(
          "https://www.bharatabroad.com/api/immigration"
        ); // Ensure this is the correct endpoint
        const data = await response.json();

        if (Array.isArray(data)) {
          // Sort the filtered data in descending order of `id`
          const sortedData = data.sort((a, b) => b.id - a.id);

          setTech(sortedData); // Update state with the sorted data
        } else {
          console.error("Fetched data is not an array");
        }
      } catch (error) {
        console.error("Error fetching tech:", error);
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    };

    fetchTech();
  }, []);

  // Split the sorted data into top 4 and remaining
  const topPicks = tech.slice(0, 4); // Last 4 items
  const remainingTech = tech.slice(4); // Remaining items
  return (
    <div>
      <NavBar />
      <div>
        {/* Top Picks Section */}
        <section className="mt-2 ">
          <Container fluid>
            <Row className="py-1">
              <Col xs={12} className="px-2">
                <h4 className="fw-bolder ">Top Picks</h4>
              </Col>

              {/* Display the first item separately */}
              <Col
                style={{ backgroundColor: "#ffffff" }}
                className="p-2"
                xs={{ order: 1 }}
              >
                {topPicks.length > 0 && (
                  <NavLink
                    as={Link}
                    to={`/immigration-details/${topPicks[0].id}`}
                    className=""
                    key={topPicks[0].id}
                  >
                    <div className="card border-0">
                      <img
                        src={topPicks[0].IM_Image}
                        className="card-img-top"
                        alt={topPicks[0].IM_Title}
                      />
                      <div className="card-body px-0">
                        <h5 className="text-primary-emphasis fw-bold">
                          {topPicks[0].IM_Title}
                        </h5>
                        <p className="card-title fw-500">
                          {topPicks[0].IM_Lead}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                )}
              </Col>

              {/* Map through the remaining items, starting from index 1 */}
              <Col
                xs={{ order: 2 }}
                md={12}
                lg={6}
                style={{ backgroundColor: "#ffffff" }}
                className="p-2"
              >
                {topPicks.slice(1).map((item) => (
                  <NavLink
                    as={Link}
                    to={`/news/${item.id}`}
                    className="mt-2 mb-3"
                    key={item.id}
                  >
                    <div className="card border-0 rounded-0">
                      <div className="row g-0">
                        <div className="col-xs-12 col-lg-5">
                          <img
                            src={item.IM_Image}
                            className="card-img-right img-fluid rounded-3 mb-3"
                            alt={item.IM_Title}
                            style={{
                              height: isLargeScreen ? "130px" : "auto",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="col-xs-12 col-lg-7 p-0">
                          <div className="card-body px-0 px-lg-2 pt-0 mt-0">
                            <p
                              className="text-primary-emphasis fw-bold mb-0"
                              style={{ fontSize: "13px" }}
                            >
                              <span>{item.IM_Title}</span>
                            </p>
                            <p
                              className="card-title fw-500"
                              style={{ fontSize: "14px" }}
                            >
                              {item.IM_Lead}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ))}
              </Col>
            </Row>
          </Container>
        </section>

        {/* Remaining Tech Section */}
        <section>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h5 className="fw-bold  py-1">Immigiration Policies</h5>
              </Col>
              <Col lg={9}>
                <Row>
                  <Col lg={4}>
                    <Card className="p-0 border-0">
                      <Card.Img
                        variant="top rounded-0"
                        src="https://advocatetanwar.com/wp-content/uploads/2024/04/29147473_1688212967_WhatsApp_Image_2023-07-01_at_4.31.05_PM.jpg"
                      />
                      <Card.Body className="p-0 pt-3">
                        <Card.Text className="fw-bold mb-1 text-primary-emphasis">
                          Overview of Indian Immigration Patterns and Trends
                        </Card.Text>
                        <Card.Text className="mb-1">
                          Recent reforms in visa procedures complement India’s
                          economic growth and facilitate foreign investors to do
                          business in India.
                        </Card.Text>
                        <div className="text-end"></div>
                        <Link
                          className="text-dark fw-bold"
                          to="/immigiration/india"
                        >
                          Read More
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card className="p-0 border-0">
                      <Card.Img
                        variant="top rounded-0"
                        src="https://en.usvisaservice.de/assets/USA/us-behoerden-einwanderungsbehoerde-usa.jpg"
                      />
                      <Card.Body className="p-0 pt-3">
                        <Card.Text className="fw-bold mb-1 text-primary-emphasis">
                          U.S. Citizenship and Immigration Services
                        </Card.Text>
                        <Card.Text className="mb-1">
                          The United States is a global hub of opportunity and
                          quality of life, attracting individuals and families
                          from around the world.
                        </Card.Text>
                        <div className="text-end"></div>
                        <Link
                          className="text-dark fw-bold"
                          to="/immigiration/usa"
                        >
                          Read More
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col lg={3} md={3}>
                <div className="d-flex justify-content-center">
                  <small>Advertisement</small>
                </div>
                <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                  <Image
                    className="mx-auto d-block pt-2"
                    src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                    fluid
                  />
                </Link>
                <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                  <Image
                    className="mx-auto d-block pt-2 border"
                    src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                    fluid
                  />
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default Immigiration;
